<template>
  <SettingsBlock :team-id="team_id"></SettingsBlock>
</template>

<script>
import { mapGetters } from "vuex";
import SettingsBlock from "../components/SettingsBlock.vue";
export default {
  props: {
    teamId: { type: String, default: null },
  },
  computed: {
    ...mapGetters(["user"]),
    team_id() {
      return this.teamId ? this.teamId : this.user.team_id;
    },
  },
  components: { SettingsBlock },
};
</script>

<style lang="scss" scoped></style>
